const { trackEvent } = require('./tracking');
const { eshopShareTracks } = require('./eshopTracks');

export const handleShare = (isWebview, shareText, shareLink, setShowSnackbar, reportPageTracks) => {
  try {
    if (isWebview && (window?.MobileWebKit?.actions?.share)) {
      trackEvent(eshopShareTracks(reportPageTracks, 'native app'));
      window.MobileWebKit.actions.share(shareText, shareLink);
    } else if (navigator?.share) {
      trackEvent(eshopShareTracks(reportPageTracks, 'native browser'));
      navigator.share({
        title: shareText,
        text: shareText,
        url: shareLink,
      });
    } else if (navigator?.clipboard?.writeText) {
      trackEvent(eshopShareTracks(reportPageTracks, 'link'));
      navigator.clipboard.writeText(shareLink);
      setShowSnackbar(true);
    } else {
      throw new Error('Unable to share');
    }
  } catch (error) {
    throw new Error('Share error', error.message);
  }
};
