import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Share16 from '@andes/icons/Share16';
import Snackbar from '@andes/snackbar';

const { useRenderContext } = require('../../pages/home/context');
const { ANDES_BLUE_500 } = require('../../utils/constants/shop/colors');
const { trackEvent } = require('../../utils/tracking');
const { handleShare } = require('../../utils/handleShare');
const { eshopReportTracks } = require('../../utils/eshopTracks');

const ReportPage = ({
  report = {},
  share = {},
  link = {},
  namespace = 'ui-ms-report-page-wrapper',
}) => {
  const { text: reportText, href: reportLink } = (report?.text && report) || link || {};
  const { text: shareText, link: shareLink, snackbar_message: snackbarMessage } = share || {};
  const { marketplaceInfo, deviceType, reportPageTracks } = useRenderContext();
  const { isWebview } = marketplaceInfo;
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    if (showSnackbar) {
      setTimeout(() => { setShowSnackbar(false); }, 5000);
    }
  }, [showSnackbar]);

  const isValid = () => (typeof window !== 'undefined'
  && typeof window.document !== 'undefined'
  && window.document.body !== null
  );

  return (
    <div className={`${namespace}`}>
      {shareText && deviceType !== 'desktop' && (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        onClick={() => handleShare(isWebview, shareText, shareLink, setShowSnackbar, reportPageTracks)}
        role="button"
        className={`${namespace}__link ${namespace}__link--share`}
        tabIndex={0}
      >
        <Share16 color={ANDES_BLUE_500} />
        {shareText}
      </div>)}
      {// eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <a
          onClick={() => trackEvent(eshopReportTracks(reportPageTracks))}
          href={reportLink}
          target="_blank"
          rel="noreferrer"
          className={`${namespace}__link`}
        >
          {reportText}
        </a>
        }
      {
        isValid()
        && <Snackbar color="black" delay={5000} message={snackbarMessage} show={showSnackbar} />
      }
    </div>
  );
};

ReportPage.propTypes = {
  link: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
  }),
  report: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
  }),
  share: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
  }),
  namespace: PropTypes.string,
};

export default ReportPage;
